'use client';
import Link from 'next/link';
import React from 'react';
import SocialLink from '@ui/Link/SocialLink';
import Button from '@ui/Button';
import { isValidEmail } from '@util/validation';
import { subscribeToList } from '@util/firestore/messages';
import Input from '@ui/Input';
import { logError } from '@util/logError';
import { AppStoreButtons } from 'app/get-the-app/components/GetTheApp';
import { PhoneIcon } from './icons';
import { MailIcon, MessageSquareIcon } from 'lucide-react';
import { usePathname } from 'next/navigation';

const FooterSection = ({
  children,
  title,
}: {
  title: string;
  children: React.ReactNode[];
}) => {
  return (
    <div className="sm:order-0 order-1 flex flex-col gap-[1.6rem] text-[1.6rem]">
      <span className="font-semibold">{title}</span>
      {children?.length &&
        children?.map((child, i) => (
          <div
            key={`key-${i}`}
            className="flex flex-col gap-[1.6rem] font-medium hover:text-brand-primary"
          >
            {child}
          </div>
        ))}
    </div>
  );
};

const Footer = () => {
  const [emailInput, setEmailInput] = React.useState<{
    email: string;
    success: boolean;
    loading: boolean;
    error: string;
  }>({ email: '', error: '', success: false, loading: false });

  const pathname = usePathname();

  const onSubscribe = async (ev?: Event) => {
    ev?.preventDefault();
    if (isValidEmail(emailInput.email)) {
      setEmailInput({
        email: emailInput.email,
        error: '',
        success: false,
        loading: true,
      });
      try {
        await subscribeToList(emailInput.email);
        setEmailInput({
          email: '',
          error: '',
          success: true,
          loading: false,
        });
      } catch (e) {
        const msg = (e as Error).message || 'Failed to send';
        setEmailInput({
          email: emailInput.email,
          error: msg,
          success: false,
          loading: false,
        });
        logError(e);
      }
    } else {
      setEmailInput({
        email: emailInput.email,
        error: 'Invalid email',
        success: false,
        loading: false,
      });
    }
  };

  const BootTradeInFooterSection = () => {
    return (
      <div className="flex w-full bg-[#F5F5F5] px-8 py-16 sm:mx-auto sm:px-[15rem]">
        <div className="flex flex-col gap-y-8">
          <h3 className="text-[2.1rem] font-semibold text-black">
            MX Locker Boot Trade-In Program – Upgrade Your Boots & Save
          </h3>

          <p className="text-[1.8rem] font-medium text-[#444444]">
            Looking to upgrade your motocross boots? With the MX Locker Boot
            Trade-In Program, you can trade in your used boots and get credit
            toward a new pair. Whether you’re upgrading to the latest
            Alpinestars, Fox Racing, or Gaerne boots, our program makes it easy
            to save while keeping quality gear in circulation. Simply upload
            photos, choose your preferred upgrade, and receive a trade-in offer.
            Get the most value for your used dirt bike boots while ensuring
            another rider gets access to affordable, high-quality gear. Start
            your trade-in today and step into a better ride!
          </p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex h-fit w-full items-center bg-brand-secondary px-8 py-[4rem] sm:px-[15rem]">
        <div className="flex w-full flex-col items-center justify-between sm:flex-row">
          <p className="text-[2.1rem] font-bold text-white">
            Need help? We&apos;re riders just like you.
          </p>

          <div className="mt-8 flex flex-col items-center gap-x-[10rem] gap-y-8 sm:mt-0 sm:flex-row sm:gap-y-0">
            <Link
              href="tel:4077836168"
              className="flex cursor-pointer items-center gap-x-4"
            >
              <PhoneIcon className={'h-[2.5rem] w-[2.5rem] text-white'} />

              <span className="text-[2.1rem] font-bold text-white">
                (407) 783-6168
              </span>
            </Link>

            <Link href={'/contact'} className="flex items-center gap-x-4">
              <MessageSquareIcon
                className={'h-[2.5rem] w-[2.5rem] text-white'}
              />

              <span className="text-[2.1rem] font-bold text-white">
                Chat Us
              </span>
            </Link>

            <Link
              href="mailto:support@mxlocker.com"
              className="flex items-center gap-x-4"
            >
              <MailIcon className={'h-[2.5rem] w-[2.5rem] text-white'} />

              <span className="text-[2.1rem] font-bold text-white">
                Email Us
              </span>
            </Link>
          </div>
        </div>
      </div>

      {pathname?.includes('boot-trade-in') && <BootTradeInFooterSection />}

      <div className="flex w-full justify-center border-[1px] border-t bg-brand-darker-white px-[1.6rem] lg:px-[12rem] ">
        <div className="flex w-full max-w-content  select-none flex-col justify-end ">
          <div className="flex h-fit flex-col items-start justify-between gap-[3.2rem] py-[4rem]  md:flex-row md:gap-[6.4rem] lg:py-[6.4rem]">
            <div className="xs:w-auto flex h-fit w-full flex-col items-center  sm:gap-[3.2rem]  md:w-[40rem] md:items-start xl:w-[51rem]">
              <div className="flex h-full w-full flex-col gap-[2rem]  md:w-fit ">
                <span className="whitespace-nowrap text-[1.6rem]  font-semibold">
                  Get the best of MX Locker in your inbox.
                </span>

                <div className="flex flex-col gap-[1.6rem]">
                  <div className="col-span-2 mr-5 w-full">
                    <Input
                      full
                      onDark
                      type="email"
                      value={emailInput.email}
                      onChange={(ev) =>
                        setEmailInput({
                          email: ev.target.value,
                          error: '',
                          success: false,
                          loading: false,
                        })
                      }
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                          onSubscribe();
                        }
                      }}
                      noPadding
                      placeholder="Enter your email"
                      error={!!emailInput.error}
                      trailingIconButton={
                        <Button
                          buttonType="button"
                          onClick={onSubscribe}
                          type="secondary"
                          text="Subscribe"
                          width="small"
                          height="small"
                          loading={emailInput.loading}
                        />
                      }
                    />
                    {emailInput.error && (
                      <p className="p-3 text-brand-red">{emailInput.error}</p>
                    )}
                    {emailInput.success && (
                      <p className="p-3 text-brand-green">
                        Success! Check your email to confirm your subscription.
                      </p>
                    )}
                    <p className="mt-4 text-center text-[0.8rem] text-brand-dark-gray">
                      By clicking subscribe, I agree to receive exclusive offers
                      & promotions, news & reviews, and personalized tips for
                      buying and selling on MX Locker.
                    </p>
                  </div>
                  {/* <Button
                  height="small"
                  buttonType="button"
                  onClick={onSubscribe}
                  type="secondary"
                  text="Subscribe"
                  width="normal"
                  loading={emailInput.loading}
                /> */}
                </div>
                <span className="text-center text-[1.8rem] font-semibold uppercase">
                  Download the MX Locker App
                </span>
                <div>
                  <AppStoreButtons />
                </div>
              </div>
            </div>

            <div className="grid w-full grid-cols-2 gap-x-[2.4rem] gap-y-[3.2rem]  lg:flex  lg:grid-cols-2 lg:justify-between">
              <FooterSection title="Shop">
                <Link href="/shop/riding-gear">Riding Gear</Link>
                <Link href="/shop/parts">Bike Parts</Link>
                <Link href="/shop/oem-dirt-bike-parts">OEM Parts</Link>
                <Link href="/dirt-bikes-for-sale">Dirt Bikes</Link>
                <Link href="/brands">Brands</Link>
              </FooterSection>
              <FooterSection title="Sell">
                <Link href="/create-listing">List an Item</Link>
                <Link href="/how-it-works">How it Works</Link>
                <Link href="/getting-paid">Getting Paid</Link>
                <Link href="/sell-my-dirt-bike">Sell My Dirt Bike</Link>
                <Link href="/seller-obligations">Seller Obligations</Link>
                <Link href="/shipping">Shipping</Link>
              </FooterSection>
              <FooterSection title="Legal">
                <Link href="/terms-and-conditions">Terms & Conditions</Link>
                <Link href="/privacy-policy">Privacy Policy</Link>
                <Link href="/refund-policy">Refund Policy</Link>
                <Link href="/return-assurance">Return Assurance</Link>
                <Link href="/buyer-and-seller-protection">
                  Buyer & Seller Protection
                </Link>
                <Link href="/fee-policy">Fee Policy</Link>
              </FooterSection>
              <FooterSection title="Other">
                <Link href="/help">Help Center</Link>
                <Link href="/blog">Blog</Link>
                <Link href="/careers">Careers</Link>
                <Link href="/about">About Us</Link>
                <Link href="/contact">Contact Us</Link>
                <Link href="/accessibility">Accessibility</Link>
                <Link href="/road-2-recovery">Road 2 Recovery</Link>
                {/* <Link href="/get-the-app">Get The App</Link> */}
                {/* <Link href="/">Sitemap</Link> */}
              </FooterSection>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center border-t-[0.1rem] border-brand-lighter-gray  bg-brand-darker-white px-[1.6rem] pb-[2rem] sm:pb-0 lg:px-[12rem]">
        <div className="flex w-full max-w-[120rem]  select-none flex-col justify-end ">
          <div>
            <div className="flex w-full flex-col justify-between gap-[1.6rem] py-[2.4rem] md:flex-row  ">
              <p className="flex flex-nowrap items-center justify-center whitespace-nowrap text-[1.4rem] text-brand-dark-gray lg:justify-start">
                <span className="mr-[2.4rem] text-center">
                  MX Locker © 2024{' '}
                </span>
                <Link href="/accessibility">Accessibility</Link>
              </p>
              <div className="flex  justify-center gap-[2.4rem] sm:mt-0  lg:justify-start ">
                <SocialLink variant="facebook" />
                <SocialLink variant="instagram" />
                <SocialLink variant="youtube" />
                <SocialLink variant="tiktok" />
              </div>

              {/* <AcceptedCards /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
